import {Row} from "antd";
import { withTranslation, TFunction } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import i18n from "i18next";
import {
  FooterSection,
  LogoContainer,
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: { t: TFunction }) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
              <LogoContainer>
                <SvgIcon
                    src="logo.svg"
                    aria-label="homepage"
                    width="150px"
                    height="96px"
                />
              </LogoContainer>
              <text style={{alignSelf: 'center'}}>© 2024 no-logo dev. All rights reserved.</text>
          </Row>
        </Container>
      </FooterSection>
    </>
  );
};

export default withTranslation()(Footer);
